<template>
  <section
    class="search-container d-none d-md-flex sp-menu"
    :class="[showSelected ? 'showSelected' : '']"
    :key="menuKey"
  >
    <div class="w-100 sp-container">
      <div style="margin: 0 auto" :key="menuKey">
        <v-slide-group
          show-arrows
          class="search-bar"
          center-active
          v-model="model"
          selected-class="shopmall-nav-active"
        >
          <v-slide-group-item :key="i" v-for="(item, i) in items">
            <v-menu
              v-if="item.children"
              :key="item.id"
              content-class="mall-menu"
              open-on-hover
            >
              <template v-slot:activator="{ props }">
                <div
                  :id="`PARENTNAME${item.nameEn}`"
                  v-bind="props"
                  @click="setAccessories(item)"
                  class="nav-1 text-left d-flex flex-column justify-center pa-2 py-4"
                  :class="[i == items.length - 1 ? 'mr-0' : 'mr-8']"
                >
                  <p
                    v-for="(i, index) in item.firstNameEn"
                    class="text-center pointer fs-14"
                    :class="[
                      item.id === accessoriesId && route.path !== '/'
                        ? 'shopmall-nav-active'
                        : ''
                    ]"
                  >
                    {{ i }}
                  </p>
                </div>
              </template>
              <div
                class="px-8 py-4 celevation d-flex fs-14 fb-500 fc-1a"
                style="background-color: #fff; border-radius: 2px"
              >
                <!-- 奇数 -->
                <div class="d-flex flex-column pr-4">
                  <div
                    v-for="(menu, index) in item.odds"
                    class="nav-sub text-left mt-2 fc-1a"
                  >
                    <span @click="setAccessories(menu, item)">
                      {{ menu.nameEn }}
                    </span>
                  </div>
                </div>
                <div class="d-flex flex-column pl-4">
                  <div
                    v-for="(menu, index) in item.evens"
                    class="nav-sub text-left mt-2"
                  >
                    <span @click="setAccessories(menu, item)">
                      {{ menu.nameEn }}
                    </span>
                  </div>
                </div>
              </div>
            </v-menu>
            <div
              class="nav-1 text-left d-flex flex-column justify-center pa-2 py-4"
              :class="[i == items.length - 1 ? 'mr-0' : 'mr-8']"
              v-else
              @click="setAccessories(item)"
              :id="`PARENTNAME${item.nameEn}`"
            >
              <p
                v-for="(i, index) in item.firstNameEn"
                class="text-center pointer fs-14"
                :class="[
                  item.id === accessoriesId && route.path !== '/'
                    ? 'shopmall-nav-active'
                    : ''
                ]"
              >
                {{ i }}
              </p>
            </div>
          </v-slide-group-item>
        </v-slide-group>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useUserStore, useProductStore } from "~/stores";
const config = useRuntimeConfig();
const primaryColor = config.public.primaryColor;
const store = useUserStore();
const productStore = useProductStore();
const category = useCategory();
const showSelected = ref(false);

// 获取类目列表
const items = ref([]);
const menuKey = ref(Date.now());
// 获取分类
const categorysListData = useState("categorysData");
if (!categorysListData.value) {
  const categorysData = await useAsyncData(
    "categorys",
    () =>
      $fetch(`${config.public.serverBaseUrl}product/api/v1/category/list`, {
        headers: {
          tenantId: config.public.tenantId,
          Systemcode: "mall",
          erpTenantId: config.public.erpTenantId
        },
        method: "GET"
      }),
    {}
  );
  if (categorysData?.data?.value?.code === "000000") {
    let res = categorysData?.data.value.data;
    res = JSON.parse(JSON.stringify(res));
    res = res.map(item => {
      let nameEnArr = item.nameEn.split("&");
      nameEnArr = nameEnArr.map((name, index, arr) => {
        if (index > 0) {
          name = `& ${name}`;
        }
        return name;
      });
      item.firstNameEn = nameEnArr;
      let odds = [];
      let evens = [];
      if (item.children) {
        item.children = item.children.map((child, index) => {
          if (index % 2 === 0) {
            odds.push(child);
          } else {
            evens.push(child);
          }
        });
        item.odds = odds;
        item.evens = evens;
      }
      return item;
    });
    useState("categorysData", () => res || []);
    items.value = res;
  }
} else {
  items.value = categorysListData.value;
}

const getCategorys = async () => {
  let res;
  if (store.menuList.length > 0) {
    res = store.menuList;
  } else {
    res = await category.getCategorys();
    store.setMenuList(res);
  }
  res = JSON.parse(JSON.stringify(res));
  res = res.map(item => {
    let nameEnArr = item.nameEn.split("&");
    nameEnArr = nameEnArr.map((name, index, arr) => {
      if (index > 0) {
        name = `& ${name}`;
      }
      return name;
    });
    item.firstNameEn = nameEnArr;
    let odds = [];
    let evens = [];
    if (item.children) {
      item.children = item.children.map((child, index) => {
        if (index % 2 === 0) {
          odds.push(child);
        } else {
          evens.push(child);
        }
      });
      item.odds = odds;
      item.evens = evens;
    }
    return item;
  });
  items.value = res;
};

const resize = () => {
  window &&
    window.addEventListener("resize", () => {
      menuKey.value = Date.now();
    });
};

// 设置二级目录
const setAccessories = (menu, item) => {
  productStore.setParentMenuName(item ? item.nameEn : menu.nameEn);
  category.setCategorysInfo(menu);
};

const model = ref(0);
const { $Utils } = useNuxtApp();
const route = useRoute();
const accessoriesId = ref("");
const getModel = () => {
  let routePathArr = route.path?.split("/");
  if (!routePathArr || !routePathArr[1] || routePathArr[1] !== "category") {
    model.value = "";
    showSelected.value = false;
    return;
  }
  let nameEn = routePathArr[2];
  if (!nameEn) {
    return;
  }
  nameEn = $Utils.replaceDashWithSpaces(nameEn);
  const menuList = store.menuList;
  for (let i = 0, l = menuList.length; i < l; i++) {
    if (nameEn === menuList[i].nameEn) {
      model.value = i;
      accessoriesId.value = menuList[i].id;
      showSelected.value = true;
      return;
    }
  }
};

onMounted(() => {
  // getCategorys();
  resize();
  setTimeout(() => {
    getModel();
  }, 300);
});
</script>

<style lang="scss" scope>
.celevation {
  box-shadow: 0px 5px 5px -3px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
    0px 8px 10px 1px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
    0px 3px 14px 2px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12));
}
.search-container {
  --primary-color: v-bind(primaryColor);
  .search-bar {
    margin: 0 auto;
    font-size: 16px;
    span {
      display: inline-block;
      text-align: center;
      cursor: pointer;
      color: #555;
    }
    .nav-1 {
      padding: 16px 2px;
      font-weight: 500;
      color: #666666;
      &:hover {
        color: var(--primary-color);
      }
    }
    span:hover {
      color: var(--primary-color);
      font-weight: 600;
    }
  }
}
.showSelected .shopmall-nav-active {
  color: var(--primary-color);
  font-weight: 700;
}
.v-list-item:hover {
  color: var(--primary-color);
}
.nav-1 span:hover {
  color: #fff !important;
  border-radius: 4px;
}
.nav-1 .nav-content {
  display: inline-block;
  padding: 4px 8px;
  &:hover {
    p {
      color: #fff;
    }
  }
}
.nav-sub {
  display: inline-block;

  span {
    display: inline-block;
    padding: 4px;
    cursor: pointer;
    &:hover {
      padding: 4px;
      color: var(--primary-color);
      background: #efefef;
      border-radius: 4px;
    }
  }
}
</style>
<style>
.sp-menu .v-slide-group__content {
  display: flex;
  justify-content: space-between;
}
.sp-menu .v-slide-group__next {
  justify-content: right;
}
.sp-menu .v-slide-group__prev {
  justify-content: left;
}
.v-slide-group-item--active {
  color: var(--primary-color);
}
</style>
