export const useServerApi = async (
  endpoint,
  method = "GET",
  headers = {},
  body = null
) => {
  const config = useRuntimeConfig(); // 获取 Nuxt 配置

  const defaultHeaders = {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache", // 禁用缓存
    tenantId: config.public.tenantId,
    Systemcode: "mall",
    erpTenantId: config.public.erpTenantId,
    ...headers // 允许自定义 headers
  };

  const options = {
    method,
    headers: defaultHeaders,
    body: body ? JSON.stringify(body) : null
  };

  try {
    const { data, error } = await useAsyncData(
      `${endpoint}-${Date.now()}`,
      () => $fetch(`${config.public.serverBaseUrl}${endpoint}`, options),
      { server: true }
    );

    if (error.value) {
      console.error(`Error fetching ${endpoint}:`, error.value);
      return null;
    }

    return data.value;
  } catch (err) {
    console.error(`Error during API call to ${endpoint}:`, err);
    return null;
  }
};
