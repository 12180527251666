//获取轮播图数据
export const fetchCarouselImages = async () => {
  return await useServerApi("system/home-carousel/listByTenantId");
};
// 获取Best Sellers
export const fetchBestSellers = async () => {
  return await useServerApi("product/api/v1/product/listBestSellers", "GET", {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    tokenId: "" // 其他自定义 headers
  });
};
// 获取New Arrivals
export const fetchNewArrivals = async () => {
  return await useServerApi("product/api/v1/product/listNewArrival", "GET", {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    tokenId: ""
  });
};
// 获取类目
export const fetchCategories = async () => {
  return await useServerApi(
    "system/home-category/listByTenantId",
    "POST",
    {},
    {}
  );
};

// 商城-查询进行中的订单折扣
export const fetchInProgress = async () => {
  return await useServerApi(
    "product/api/v1/promotions/discount/orderInProgress"
  );
};

// 商城-查询进行中的清仓活动
export const fetchActivityInProgress = async () => {
  return await useServerApi(
    "product/api/v1/promotions/clearance/activityInProgress"
  );
};

// 获取清仓商品
export const fetchClearanceItems = async data => {
  return await useServerApi(
    "product/api/v1/promotions/clearance/activityProductPage",
    "POST",
    {},
    data
  );
};

// 获取评价
export const fetchEvaluateList = async () => {
  return await useServerApi("customer/reviews/listHomeReviews");
};
